<template>
  <v-container>
    <v-dialog v-model="formReturn.show" max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formReturn.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">Returning Batch {{formReturn.model.id}}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-datetime-picker label="Return Date" v-model="computedInDateFormReturn" :text-field-props="{rules: formReturn.rules.in_date}"></v-datetime-picker>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="formReturn.show = false">
              Cancel
            </v-btn>
            <v-btn color="primary" :disabled="!formReturn.valid" v-on:click="saveFormReturn">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="formMain.show" persistent max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formMain.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">{{formMain.isAddMode ? 'Add' : 'Edit'}} Borrows</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete label="Borrower" v-model="formMain.model.manager_id" :rules="formMain.rules.manager_id" :items="formItems.users" item-text="name" item-value="id" :loading="formMain.loading.manager" v-on:update:search-input="onUpdateSearchInputManager">
                    <template slot="prepend">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScannerManager = !formMain.showScannerManager" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <StreamBarcodeReader v-if="formMain.showScannerManager" v-on:decode="onScannerDecodeManager"></StreamBarcodeReader>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker label="Borrow Date *" v-model="computedOutDate" :text-field-props="{rules: formMain.rules.out_date}"></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker label="Return Date" v-model="computedInDate" :text-field-props="{rules: formMain.rules.in_date}"></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker label="Expected Return Date *" v-model="computedExpectedInDate" :text-field-props="{rules: formMain.rules.expected_in_date}"></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Comment" v-model="formMain.model.comment" :rules="formMain.rules.comment" auto-grow rows="2"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="formMain.show = false">
              Cancel
            </v-btn>
            <v-btn v-if="formMain.isAddMode" color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Add
            </v-btn>
            <v-btn v-else color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dgDelete.show" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete?
        </v-card-title>
        <v-card-text>Are you sure to delete borrow: {{dgDelete.item ? dgDelete.item.id : '--'}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text v-on:click="dgDelete.show = false">
            Cancel
          </v-btn>
          <v-btn color="error" v-on:click="removeItem(dgDelete.item)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="my-1">
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="success" block v-on:click="addItem" v-if="auth.user.role.name == 'Admin'"><v-icon left>mdi-plus</v-icon>Add</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="info" block v-on:click="viewItem()">View All<v-icon right>mdi-chevron-right</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items" item-key="name" :search="search" v-model="selected" :show-select="false">
      <template v-slot:top>
        <v-card color="primary white--text pa-2">
          <v-row align="center" no-gutters>
            <v-col cols="12" sm="8">
              <v-card-title>Borrows</v-card-title>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="search" clearable flat solo hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
            </v-col>
          </v-row>

        </v-card>
      </template>
      <template v-slot:item.out_date="{ item }">
        <span>{{formatDT(item.out_date)}}</span>
      </template>
      <template v-slot:item.in_date="{ item }">
        <span>{{formatDT(item.in_date)}}</span>
      </template>
      <template v-slot:item.expected_in_date="{ item }">
        <span>{{formatDT(item.expected_in_date)}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="editItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="confirmRemove(item)" v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions-more="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on:click="showReturnItemDialog(item)" v-bind="attrs" v-on="on"><v-icon>mdi-home-import-outline</v-icon></v-btn>
          </template>
          <span>Return Items</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions-view="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on:click="viewItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field v-model="calories" type="number" label="less"></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
var moment = require('moment-timezone');
export default {
  data: function() {
    return {
      search: "",
      // headers: [
      //   { text: 'Batch ID', value: 'id' },
      //   { text: 'Borrower', value: 'manager.name'},
      //   { text: 'Borrow Date', value: 'out_date', width: '110px' },
      //   { text: 'Actual Return Date', value: 'in_date', width: '110px' },
      //   { text: 'Expected Return Date', value: 'expected_in_date', width: '110px' },
      //   { text: 'Comment', value: 'comment', width: '25%'},
      //   { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      //   { text: 'Return', value: 'actions-more', sortable: false, width: '60px' },
      //   { text: 'View', value: 'actions-view', sortable: false, width: '60px' }
      // ],
      items: [],
      selected: [],
      editingItem: null,
      dgDelete: {
        show: false,
        item: null,
      },
      formItems: {
        users: [],
        outtypes: [],
      },
      formMain: {
        show: false,
        isAddMode: true,
        valid: false,
        showScannerManager: false,
        loading: {
          manager: false,
          code: false
        },
        model: {
          id: null,
          manager_id: null,
          out_date: null,
          in_date: null,
          expected_in_date: null,
          comment: null,
          manager: null,
          outtype_id: null,
          outtype: null,
        },
        rules: {
          out_date: [
            v => !!v || 'Required'
          ],
          in_date: [
            v => !v || moment(v) > moment(this.formMain.model.out_date) || 'Must be after Borrow Date'
          ],
          expected_in_date: [
            v => !!v || 'Required',
            v => moment(v) > moment(this.formMain.model.out_date) || 'Must be after Borrow Date'
          ],
          manager_id: [
            v => !!v || 'Required'
          ],
          outtype_id: [
            v => !!v || 'Required'
          ],
        }
      },
      formReturn: {
        show: false,
        valid: false,
        loading: {
        },
        model: {
          id: null,
          in_date: null,
          out_date: null,
        },
        rules: {
          in_date: [
            v => !!v || 'Required',
            v => moment(v) > moment(this.formReturn.model.out_date) || 'Must be after Borrow Date'
          ],
        }
      },
    }
  },
  computed: {
    headers: {
      get: function() {
        if(this.auth.user.role.name == 'Admin'){
          return [
            { text: 'Batch ID', value: 'id' },
            { text: 'Borrower', value: 'manager.name'},
            { text: 'Borrow Date', value: 'out_date', width: '110px' },
            { text: 'Actual Return Date', value: 'in_date', width: '110px' },
            { text: 'Expected Return Date', value: 'expected_in_date', width: '110px' },
            { text: 'Comment', value: 'comment', width: '25%'},
            { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
            { text: 'Return', value: 'actions-more', sortable: false, width: '60px' },
            { text: 'View', value: 'actions-view', sortable: false, width: '60px' }
          ];
        }else{
          return [
            { text: 'Batch ID', value: 'id' },
            { text: 'Borrower', value: 'manager.name'},
            { text: 'Borrow Date', value: 'out_date', width: '110px' },
            { text: 'Actual Return Date', value: 'in_date', width: '110px' },
            { text: 'Expected Return Date', value: 'expected_in_date', width: '110px' },
            { text: 'Comment', value: 'comment', width: '25%'},
            { text: 'View', value: 'actions-view', sortable: false, width: '60px' }
          ];
        }
      },
    },
    computedOutDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.out_date = null;
        else if(moment(val).isValid())
          this.formMain.model.out_date = this.formatToUTC(val);
      },
      get: function() {
        return this.formatDT(this.formMain.model.out_date);
      }
    },
    computedInDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.in_date = null;
        else if(moment(val).isValid())
          this.formMain.model.in_date = this.formatToUTC(val);
      },
      get: function() {
        return this.formatDT(this.formMain.model.in_date);
      }
    },
    computedExpectedInDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.expected_in_date = null;
        else if(moment(val).isValid())
          this.formMain.model.expected_in_date = this.formatToUTC(val);
      },
      get: function() {
        return this.formatDT(this.formMain.model.expected_in_date);
      }
    },
    computedInDateFormReturn: {
      set: function(val) {
        if(val == '' || val == null)
          this.formReturn.model.in_date = null;
        else if(moment(val).isValid())
          this.formReturn.model.in_date = this.formatToUTC(val);
      },
      get: function() {
        return this.formatDT(this.formReturn.model.in_date);
      }
    }
  },
  mounted() {
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      var _this = this;
      this.get('/inoutsbatch/getall/1001', function(res){
        _this.items = res['data']['data']
      })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/user/getall', function(res){
        _this.formItems.users = res['data']['data']
      })
      this.get('/outtype/getall', function(res){
        _this.formItems.outtypes = res['data']['data']
      })
    },
    addItem: function() {
      this.formMain.isAddMode = true;
      this.formMain.show = true;
      this.resetForm();
    },
    saveForm: function() {
      if (!this.formMain.valid) return;

      var url = '/inoutsbatch/add';
      if(!this.formMain.isAddMode)
        url = '/inoutsbatch/update/' + this.formMain.model.id;

      var _this = this;
      this.formMain.show = false;
      this.post(url, this.formMain.model, function(){
        _this.formMain.show = false;
        _this.getAllItems();
        _this.resetForm();
      }, function(){
        _this.formMain.show = true;
      }, {showSuccess: true, showError: true, showLoading: true});
    },
    resetForm: function() {
      this.formMain.model.id = null;
      this.formMain.model.name = null;
      this.$refs['form-main'].resetValidation();
    },
    editItem: function(item) {
      this.formMain.isAddMode = false;
      this.editingItem = JSON.parse(JSON.stringify(item));
      this.formMain.model = this.editingItem;
      this.formMain.show = true;
    },
    confirmRemove: function(item) {
      this.dgDelete.item = item;
      this.dgDelete.show = true;
    },
    removeItem: function(item) {
      var _this = this;
      this.dgDelete.show = false;
      
      this.post('/inoutsbatch/delete/' + item.id, null, function(){
        _this.getAllItems();
      })
    },
    viewItem: function(item) {
        if(item){
            if(item.id)
                this.navTo("/Borrows/Details/" + item.id);
            else
                this.navTo("/Borrows/Details/-1");
        }
        else
            this.navTo("/Borrows/Details");
    },
    
    showReturnItemDialog: function(item){
      this.formReturn.model.id = item.id;
      this.computedInDateFormReturn = this.formatDT(moment());
      this.formReturn.model.out_date = this.formatDT(item.out_date);
      this.formReturn.show = true;
    },
    saveFormReturn: function() {
      var item = this.formReturn.model;
      var _this = this;
      this.post('/inoutsbatch/returnItems/' + item.id, item, function(){
        _this.getAllItems();
        _this.formReturn.show = false;
      }, {showSuccess: true, showError: true, showLoading: true});
    },
    onScannerDecodeManager: function(result) {
      // var _this = this;
      // this.formMain.model.code = result
      this.onUpdateSearchInputManager(result);
      this.formMain.showScannerManager = false;
    },
    onUpdateSearchInputManager: function(e) {
      if(!e) return;
      var code = e.match(/\d/g);
      if(!code) return;
      code = code.join('')
      console.log(code);
      if(code.length != 16) return;
      var _this = this;
      this.formMain.loading.manager = true;
      this.get('/user/getByCode/' + code, function(res){
        if(res['data']['data']){
          _this.formMain.model.manager = null
          _this.formMain.model.manager_id = null
          _this.$nextTick(() => {
            _this.formMain.model.manager = res['data']['data']
            _this.formMain.model.manager_id = _this.formMain.model.manager.id
          })
        }
        _this.formMain.loading.manager = false;
      }, function(){
        _this.formMain.loading.manager = false;
      }, {showSuccess: false, showError: true, showLoading: false})
    }
  }
}
</script>